import {PageHeader} from "../menu/PageHeader";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {getConfLabel, showSuccessToast, titleCase} from "../utils/usacmUtils";
import {CONF_LABEL_KEY_CONFERENCE} from "../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Reports.scss';
import {downloadFile, uploadFile} from "../utils/networkUtils";
import {getAbstractCount} from "../api/AbstractApi";

export const DEFAULT_ABSTRACTS_PER_PDF = 100;

export function ReportList() {
  const [abstractCount, setAbstractCount] = useState(0)
  const [abstractsPerPdf, setAbstractsPerPdf] = useState('' + DEFAULT_ABSTRACTS_PER_PDF);
  const context = useContext(UsacmContext);
  const [conf,] = context.conference;
  const confLabel = titleCase(getConfLabel(conf, CONF_LABEL_KEY_CONFERENCE));
  let abstractPdfs = getAbstractPdfs();
  const useExtendedAbstract = conf?.use_extended_abstract;
  const regUseAttendance = !!conf?.reg_use_attendance;
  const [showingAttendanceImportDialog, setShowingAttendanceImportDialog] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [importErrors, setImportErrors] = useState([]);

  function getAbstractPdfs() {
    if (abstractCount === 0) {
      return [];
    }
    const abstractsPerPdfNum = Number(abstractsPerPdf) || DEFAULT_ABSTRACTS_PER_PDF;
    const retVal = [];
    for (let i = 1; i < abstractCount + 1; i += abstractsPerPdfNum) {
      retVal.push({
        start: i,
        end: Math.min((i + abstractsPerPdfNum - 1), abstractCount),
      });
    }
    return retVal;
  }

  useEffect(() => {
    getAbstractCount((status, data, newErrors) => {
      if (status === 200) {
        setAbstractCount(data.approved);
      }
    });
  }, []);

  function beginAttendanceImport(e) {
    e.preventDefault();
    const url = "reg/attendance/import/excel/";
    uploadFile(url, 'attendance', fileToUpload, null, (code, data, errors) => {
      if (code === 200) {
        resetFileUpload();
        showSuccessToast("Attendance imported.");
      } else {
        setImportErrors(errors);
      }
    });
  }

  function resetFileUpload() {
    setImportErrors([]);
    setFileToUpload(null);
    setShowingAttendanceImportDialog(false);
  }

  return (
    <div>
      <PageHeader pageTitle="Reports"/>
      <Container fluid className="usacm-container-wide">
        <Row>
          <Col className="col-6 d-flex flex-column align-items-start">
            <div className="report-line" onClick={() => downloadFile('abstract/authors/excel/', 'authors.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Approved Authors
              </div>
            </div>

            <div className="report-line" onClick={() => downloadFile('abstract/authors/duplicates/excel/', 'duplicate_authors.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Duplicate Presenting Authors
              </div>
            </div>

            <div className="report-line" onClick={() => downloadFile('schedule/program/excel/', 'program.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> {confLabel} Program
              </div>
            </div>

            <div className="report-line d-flex ">
              <div className="d-flex align-items-center" onClick={() => downloadFile('schedule/door-signs/pdf/', 'door_signs.pdf')}>
                <FontAwesomeIcon icon="fa-file-pdf" size="2x" className="me-3 pdf-red"/> Door Signs - Portrait
              </div>
              <div className="d-flex align-items-center ms-2"
                   onClick={() => downloadFile('schedule/door-signs/pdf/landscape/', 'door_signs.pdf')}>
                / Landscape
              </div>
            </div>

            <div className="report-line" onClick={() => downloadFile('reg/chairs/excel/', 'registered_chairs.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Registered Chairs
              </div>
            </div>

            <div className="report-line" onClick={() => downloadFile('reg/presenting-author/excel/', 'pauthor_reg.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Presenting Author Registration
              </div>
            </div>

            <div className="report-line"
                 onClick={() => downloadFile('symposium/abstract-status/excel/', 'abstract_status.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Abstract Status
              </div>
            </div>

            <div className="report-line"
                 onClick={() => downloadFile('symposium/organizers/excel/', 'symposium_organizers.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Symposium Organizers
              </div>
            </div>

            <div className="report-line" onClick={() => downloadFile('schedule/app-export/schedule/excel/', 'agenda.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Whova Export - Agenda / Sessions
              </div>
            </div>

            <div className="report-line" onClick={() => downloadFile('schedule/app-export/reg/excel/', 'attendees.xlsx')}>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Whova Export - Attendees (Reg)
              </div>
            </div>

            {useExtendedAbstract &&
              <div className="report-line"
                   onClick={() => downloadFile('abstract/extended/list/excel/', 'extended_abstracts.xlsx')}>
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Extended Abstracts
                </div>
              </div>
            }

            {regUseAttendance &&
              <Fragment>
                <div className="report-line" onClick={() => downloadFile('reg/attendance/export/excel/', 'attendance.xlsx')}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Attendance Export
                  </div>
                </div>

                <div className="report-line" onClick={() => setShowingAttendanceImportDialog(true)}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon="fa-file-excel" size="2x" className="me-3 excel-green"/> Attendance Import
                  </div>
                </div>

                <div className="report-line" onClick={() => downloadFile('reg/qrcode/download/zip/', 'qrcodes.zip')}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon="fa-file-zipper" size="2x" className="me-3 text-black"/> QR Code Zip
                  </div>
                </div>
              </Fragment>
            }

          </Col>

          <Col className="col-6 d-flex flex-column align-items-start">
            {abstractCount > 0 &&
              <Fragment>
                Abstracts per pdf
                <Form.Control type="number"
                              placeholder='Number'
                              name="abstracts_per_pdf"
                              value={abstractsPerPdf}
                              onChange={e => setAbstractsPerPdf(e.target.value)}
                              style={{'display': 'inline-block', 'width': '100px', 'marginLeft': '10px'}}
                />

                {abstractPdfs.map((pdf) =>
                  <div className="report-line" key={pdf.start} onClick={() =>
                    downloadFile(`abstract/list/pdf/from/${pdf.start}/to/${pdf.end}`,
                      `abstracts_${pdf.start}-${pdf.end}.pdf`)}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon="fa-file-pdf" size="2x" className="me-3 pdf-red"/>
                      Scheduled Abstracts {pdf.start} to {pdf.end}
                    </div>
                  </div>
                )}
              </Fragment>
            }
          </Col>
        </Row>
      </Container>

      <Modal show={showingAttendanceImportDialog}
             onHide={() => resetFileUpload()}
             backdrop='static' // prevent closing on click outside
             size="lg">
        <Form onSubmit={beginAttendanceImport}>
          <Modal.Header closeButton>
            <Modal.Title>Import Attendance Data</Modal.Title>
          </Modal.Header>
          {importErrors.length === 0 &&
            <Fragment>
              <Modal.Body>
                <Form.Control
                  type="file"
                  id="attendance-import"
                  name="attendance-import"
                  label="Choose file to upload..."
                  onChange={e => setFileToUpload(e.target.files[0])}
                />
                <div>
                  <p className='mt-3'>
                    Choose a file that matches the 'Attendance Export' format.
                  </p>
                  <p>
                    <span className="text-warning fw-bold mt-3 me-2 d-inline-block">Caution!</span>
                    Uploading a file will set or clear all attendance data for all registrations!
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => resetFileUpload()}>Cancel</Button>
                <Button variant="primary" type="submit">Upload</Button>
              </Modal.Footer>
            </Fragment>
          }
          {importErrors.length > 0 &&
            <Fragment>
              <Modal.Body>
                <div className="mt-2">
                  There were errors uploading the file. Some parts of the import may have succeeded. Please review
                  the changes, modify the spreadsheet and try uploading again.
                </div>
                {importErrors.map(error =>
                  <div className="usacm-error-message mt-3" key={error.message}>{error.message}</div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => resetFileUpload()}>Close</Button>
              </Modal.Footer>
            </Fragment>
          }
        </Form>
      </Modal>

    </div>
  );
}
